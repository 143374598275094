var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",staticClass:"kriyasil",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("प्रदेश")]),_c('v-selection',{attrs:{"placeholder":"प्रदेश","options":_vm.allProvices,"label":"name","filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.selectProvince(_vm.province); }},model:{value:(_vm.province),callback:function ($$v) {_vm.province=$$v},expression:"province"}})],1)],1),(
          _vm.committeeType == undefined ||
          (_vm.committeeType != undefined &&
            (_vm.committeeType == 'जिल्ला' ||
              _vm.committeeType == 'प्रतिनिधि सभा क्षेत्र' ||
              _vm.committeeType == 'प्रदेश सभा क्षेत्र' ||
              _vm.committeeType == 'पालिका' ||
              _vm.committeeType == 'वार्ड' ||
              _vm.committeeType == 'टोल'))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("जिल्ला")]),_c('v-selection',{attrs:{"placeholder":"जिल्ला","options":_vm.districts,"label":"name","filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.selectDistrict(_vm.district); }},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}})],1)],1):_vm._e(),(
          _vm.district != '' &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined &&
              (_vm.committeeType == 'प्रतिनिधि सभा क्षेत्र' ||
                _vm.committeeType == 'प्रदेश सभा क्षेत्र' ||
                _vm.committeeType == 'पालिका' ||
                _vm.committeeType == 'वार्ड' ||
                _vm.committeeType == 'टोल')))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("प्रतिनिधिसभा नि.क्षे.नं.")]),_c('v-selection',{attrs:{"placeholder":"प्रतिनिधिसभा नि.क्षे.नं.","options":_vm.houseofrepresentatives,"label":"code","filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.selectRegion(_vm.houseofrepresentative); }},model:{value:(_vm.houseofrepresentative),callback:function ($$v) {_vm.houseofrepresentative=$$v},expression:"houseofrepresentative"}})],1)],1):_vm._e(),(
          _vm.houseofrepresentative != '' &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined &&
              (_vm.committeeType == 'प्रदेश सभा क्षेत्र' ||
                _vm.committeeType == 'पालिका' ||
                _vm.committeeType == 'वार्ड' ||
                _vm.committeeType == 'टोल')))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("प्रदेश सभा क्षेत्र")]),_c('v-selection',{attrs:{"placeholder":"प्रदेश सभा क्षेत्र","options":_vm.pradeshassemblyconstituencies,"label":"code","filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.selectMunicipality(_vm.pradeshassemblyconstituency); }},model:{value:(_vm.pradeshassemblyconstituency),callback:function ($$v) {_vm.pradeshassemblyconstituency=$$v},expression:"pradeshassemblyconstituency"}})],1)],1):_vm._e(),(
          _vm.pradeshassemblyconstituency != '' &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined &&
              (_vm.committeeType == 'पालिका' ||
                _vm.committeeType == 'वार्ड' ||
                _vm.committeeType == 'टोल')))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("न.पा./गा.पा.")]),_c('v-selection',{attrs:{"placeholder":"न.पा./गा.पा.","options":_vm.municipalities,"label":"name","filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.selectWard(_vm.municipality); }},model:{value:(_vm.municipality),callback:function ($$v) {_vm.municipality=$$v},expression:"municipality"}})],1)],1):_vm._e(),(
          _vm.municipality != '' &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined &&
              (_vm.committeeType == 'वार्ड' || _vm.committeeType == 'टोल')))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("वडा नं.")]),_c('v-selection',{attrs:{"placeholder":"वडा नं.","options":_vm.wards,"filterable":true,"disabled":_vm.disabled || _vm.meeting},on:{"input":function () { return _vm.finalSubmit(); }},model:{value:(_vm.wardno),callback:function ($$v) {_vm.wardno=$$v},expression:"wardno"}})],1)],1):_vm._e(),(
          _vm.wardno != '' &&
          !_vm.selectTole &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined && _vm.committeeType == 'टोल'))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("टोल")]),_c('v-text-field',{staticClass:"specific",attrs:{"label":_vm.changeToNepali(_vm.tole),"outlined":"","disabled":_vm.meeting},on:{"keydown":function () {
                _vm.finalSubmit();
              }},model:{value:(_vm.tole),callback:function ($$v) {_vm.tole=$$v},expression:"tole"}})],1)],1):_vm._e(),(
          _vm.wardno != '' &&
          _vm.selectTole &&
          (_vm.committeeType == undefined ||
            (_vm.committeeType != undefined && _vm.committeeType == 'टोल'))
        )?_c('div',{staticClass:"grid-item"},[_c('v-col',[_c('label',{attrs:{"for":""}},[_vm._v("टोल")]),_c('v-selection',{attrs:{"placeholder":"टोल","options":_vm.toles,"filterable":true,"disabled":_vm.disabled},on:{"input":function () { return _vm.finalSubmit(); }},model:{value:(_vm.tole),callback:function ($$v) {_vm.tole=$$v},expression:"tole"}})],1)],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }